.Container {
    width: 100vw;
    height: 100vh;
    background-color: #1A2B52;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
}

.From {
    background-color: #FFFFFF;
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 1em #000000;
    padding-inline: 20px;
    padding-block: 10px;

}

.ContainerLogo {
    height: 250px;
    width: 400px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.Logo {
    height: 250px;
    width: 400px;
}

.BtnLogar {
    height: 60px;
    width: 300px;
    font-size: 22px;
    font-weight: bold;
    background-color: #2d800c;
}

.Select {
    background-color: #fad4c6;
    height: 50px;
    font-size: 17px;
    width: 400px;
    border-width: 0px;
    border-color: #fad4c6;
    outline: none;
    outline-width: 0px;
    outline-color: #fad4c6;
    border-radius: 5px;
    padding-left: 10px;
}

.Select option {
    height: 30px;
    border: 1px solid #cbcbcb;
    padding-left: 17px;
    padding-top: 12px;
}

.Input {
    background-color: #fad4c6;
    height: 50px;
    font-size: 17px;
    width: 390px;
    border-width: 0px;
    border-color: #fad4c6;
    outline: none;
    outline-width: 0px;
    outline-color: #fad4c6;
    border-radius: 5px;
    padding-left: 10px;
}

.Senha {
    -webkit-text-security: disc;
}

input::placeholder {
    font-weight: bold;
    opacity: 0.5;
    color: #1A2B52;
}

@media screen and (min-width:2304px) {
    .From {
        width: 550px;
        height: 470px;
    }
}

@media screen and (min-width:1375px) and (max-width:2304px) {
    .From {
        width: 550px;
        height: 470px;
    }
}

@media screen and (min-width:750px) and (max-width:1375px) {
    .From {
        width: 500px;
        height: 470px;
    }
}

@media screen and (max-width:750px) and (min-width:500px) {
    .From {
        width: 450px;
        height: 470px;
    }
}

@media screen and (max-width:450px) and (min-width:300px) {
    .From {
        width: 320px;
        height: 470px;
    }

    .Select {
        background-color: #fad4c6;
        height: 50px;
        font-size: 17px;
        width: 280px;
        border-width: 0px;
        border-color: #fad4c6;
        outline: none;
        outline-width: 0px;
        outline-color: #fad4c6;
        border-radius: 5px;
        padding-left: 10px;
    }

    .Input {
        background-color: #fad4c6;
        height: 50px;
        font-size: 17px;
        width: 270px;
        border-width: 0px;
        border-color: #fad4c6;
        outline: none;
        outline-width: 0px;
        outline-color: #fad4c6;
        border-radius: 5px;
        padding-left: 10px;
    }
}

@media screen and (max-width:393px) and (min-width:300px) {
    .From {
        width: 300px;
        height: 470px;
    }

    .Container {
        width: 100%;
        height: 100vh;
        background-color: #1A2B52;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .Select {
        background-color: #fad4c6;
        height: 50px;
        font-size: 17px;
        width: 280px;
        border-width: 0px;
        border-color: #fad4c6;
        outline: none;
        outline-width: 0px;
        outline-color: #fad4c6;
        border-radius: 5px;
        padding-left: 10px;
    }

    .Input {
        background-color: #fad4c6;
        height: 50px;
        font-size: 17px;
        width: 270px;
        border-width: 0px;
        border-color: #fad4c6;
        outline: none;
        outline-width: 0px;
        outline-color: #fad4c6;
        border-radius: 5px;
        padding-left: 10px;
    }

    .ContainerLogo {
        height: 250px;
        width: 280px;
        margin-bottom: 10px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .Logo {
        height: 180px;
        width: 280px;
    }

    .BtnLogar {
        height: 60px;
        width: 200px;
        font-size: 22px;
        font-weight: bold;
        background-color: #2d800c;
    }
}

@media screen and (max-width:300px) {
    .From {
        width: 200px;
        height: 350px;
    }

    .Container {
        width: 100%;
        height: 100vh;
        background-color: #1A2B52;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .Select {
        background-color: #fad4c6;
        height: 50px;
        font-size: 17px;
        width: 180px;
        border-width: 0px;
        border-color: #fad4c6;
        outline: none;
        outline-width: 0px;
        outline-color: #fad4c6;
        border-radius: 5px;
        padding-left: 10px;
    }


    .Input {
        background-color: #fad4c6;
        height: 50px;
        font-size: 17px;
        width: 170px;
        border-width: 0px;
        border-color: #fad4c6;
        outline: none;
        outline-width: 0px;
        outline-color: #fad4c6;
        border-radius: 5px;
        padding-left: 10px;
    }

    .ContainerLogo {
        height: 200px;
        width: 100px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .Logo {
        height: 80px;
        width: 100px;
    }

    .BtnLogar {
        height: 60px;
        width: 100px;
        font-size: 22px;
        font-weight: bold;
        background-color: #2d800c;
    }
}